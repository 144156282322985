.marvel-device {
  display: inline-block;
  position: relative;
  -webkit-box-sizing: content-box !important;
  box-sizing: content-box !important;
}
.marvel-device .screen {
  width: 100%;
  position: relative;
  height: 100%;
  z-index: 1;
  background: white;
  overflow: hidden;
  display: block;
  border-radius: 1px;
  -webkit-box-shadow: 0 0 0 3px #111;
  box-shadow: 0 0 0 3px #111;
}
.marvel-device .top-bar,
.marvel-device .bottom-bar {
  height: 3px;
  background: black;
  width: 100%;
  display: block;
}
.marvel-device .middle-bar {
  width: 3px;
  height: 4px;
  top: 0px;
  left: 90px;
  background: black;
  position: absolute;
}
.marvel-device.note8 {
  background: black;
  border-radius: 24px;
  padding: 25px 7px;
  /* width: 12.288vw; */
  /* height: 44.27vh; */
  margin: 0 auto;
}
.marvel-device.note8 .overflow {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 24px;
  overflow: hidden;
}
.marvel-device.note8 .speaker {
  height: 3px;
  width: 46px;
  left: 52%;
  position: absolute;
  top: 14px;
  margin-left: -28px;
  background: #171818;
  z-index: 1;
  border-radius: 8px;
}
.marvel-device.note8 .camera {
  height: 10px;
  width: 10px;
  right: 50px;
  position: absolute;
  top: 10px;
  background: #212b36;
  z-index: 1;
  border-radius: 100%;
}
.marvel-device.note8 .camera:before {
  content: "";
  height: 6px;
  width: 6px;
  left: -22px;
  position: absolute;
  top: 2px;
  background: #212b36;
  z-index: 1;
  border-radius: 100%;
}

.marvel-device.note8 .sleep {
  width: 2px;
  height: 40px;
  background: black;
  position: absolute;
  top: 158px;
  right: -2px;
}
.marvel-device.note8 .volume {
  width: 2px;
  height: 70px;
  background: black;
  position: absolute;
  top: 118px;
  left: -2px;
}
.marvel-device.note8 .volume:before {
  content: "";
  top: 98px;
  width: 2px;
  position: absolute;
  left: 0px;
  background: black;
  height: 46px;
}
.marvel-device.note8 .inner {
  width: 100%;
  height: calc(100% - 8px);
  position: absolute;
  top: 2px;
  content: "";
  left: 0px;
  border-radius: 24px;
  border-top: 1.9px solid #9fa0a2;
  border-bottom: 1.9px solid #9fa0a2;
  background: black;
  z-index: 1;
  -webkit-box-shadow: inset 0 0 2.5px 0 rgba(255, 255, 255, 0.5);
  box-shadow: inset 0 0 2.5px 0 rgba(255, 255, 255, 0.5);
}
.marvel-device.note8 .note8-shadow {
  -webkit-box-shadow: inset 0 0 5px 0 white,
    inset 0 0 4px 0 rgba(255, 255, 255, 0.5), 0 0 15px 0 white,
    0 0 15px 0 rgba(255, 255, 255, 0.5);
  box-shadow: inset 0 0 5px 0 white, inset 0 0 4px 0 rgba(255, 255, 255, 0.5),
    0 0 15px 0 white, 0 0 15px 0 rgba(255, 255, 255, 0.5);
  height: 101%;
  position: absolute;
  top: -0.6%;
  content: "";
  width: calc(100% - 10px);
  left: 5px;
  border-radius: 25px;
  z-index: 1;
  pointer-events: none;
}
.marvel-device.note8 .screen {
  border-radius: 5px;
  -webkit-box-shadow: none;
  box-shadow: none;
  background: url("https://app.shipconvert.com/assets/img/whatsapp-template/whatsapp-bg.png");
  background-size: contain;
  overflow-y: scroll;
}
.marvel-device.note8 .screen::-webkit-scrollbar {
  display: none;
}
.marvel-device.note8.landscape {
  height: 400px;
  width: 822px;
  padding: 10px 45px;
}
.marvel-device.note8.landscape .speaker {
  height: 56px;
  width: 8px;
  top: 50%;
  margin-top: -28px;
  margin-left: 0;
  right: 25px;
  left: auto;
}
.marvel-device.note8.landscape .camera {
  top: 86px;
  right: 18px;
  left: auto;
}
.marvel-device.note8.landscape .camera:before {
  top: -22px;
  left: 5px;
}
.marvel-device.note8.landscape .sensors {
  top: 120px;
  right: 22px;
  left: auto;
}
.marvel-device.note8.landscape .sensors:before {
  top: 18px;
  left: 0;
}
.marvel-device.note8.landscape .more-sensors {
  top: 285px;
  right: 18px;
  left: auto;
}
.marvel-device.note8.landscape .more-sensors:before {
  top: 40px;
  left: 4px;
}
.marvel-device.note8.landscape .sleep {
  bottom: -2px;
  top: auto;
  right: 288px;
  width: 56px;
  height: 2px;
}
.marvel-device.note8.landscape .volume {
  width: 120px;
  height: 2px;
  top: -2px;
  right: 168px;
  left: auto;
}
.marvel-device.note8.landscape .volume:before {
  right: 168px;
  left: auto;
  top: 0;
  width: 56px;
  height: 2px;
}
.marvel-device.note8.landscape .inner {
  height: 100%;
  width: calc(100% - 8px);
  left: 2px;
  top: 0;
  border-top: 0;
  border-bottom: 0;
  border-left: 2px solid #9fa0a2;
  border-right: 2px solid #9fa0a2;
}
.marvel-device.note8.landscape .shadow {
  width: 101%;
  height: calc(100% - 20px);
  left: -0.5%;
  top: 10px;
}
.screen .wp-top-bar > div:first-child {
  background: #005048;
  display: flex;
  justify-content: flex-end;
  padding: 3px 10px;
  padding-bottom: 0px;
  align-items: center;
}
.screen .wp-top-bar > div:first-child > svg {
  margin-bottom: 3px;
}
.screen .wp-top-bar > div:first-child > span {
  font-size: 8px;
  line-height: 9px;
  display: flex;
  align-items: center;
  text-align: right;
  color: #ffffff;
}
.screen .wp-top-bar > div:first-child > span:nth-child(2) {
  margin-left: 2px;
}
.screen .wp-top-bar > div:first-child > svg:nth-child(3) {
  margin-left: 4px;
  margin-right: 6px;
}
.screen .wp-top-bar > div:nth-child(2) {
  background: #00796b;
  padding: 6px;
  padding-right: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.screen .wp-top-bar > div:nth-child(2) > img {
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  margin-left: 5px;
  margin-right: 8px;
  width: 20px;
  height: 20px;
}
.screen .wp-top-bar > div:nth-child(2) > div:first-child,
.screen .wp-top-bar > div:nth-child(2) > div:nth-child(2) {
  display: flex;
  align-items: center;
}
.screen .wp-top-bar > div:nth-child(2) > div:first-child > div {
  display: flex;
  flex-direction: column;
}
.screen
  .wp-top-bar
  > div:nth-child(2)
  > div:first-child
  > div
  > span:first-child {
  font-weight: 600;
  font-size: 10px;
  display: flex;
  align-items: center;
  color: #ffffff;
  line-height: 12px;
  text-align: left;
}
.screen
  .wp-top-bar
  > div:nth-child(2)
  > div:first-child
  > div
  > span:nth-child(2) {
  font-size: 6px;
  line-height: 7px;
  display: flex;
  align-items: center;
  color: rgba(255, 255, 255, 0.7);
}
.screen .wp-top-bar > div:nth-child(2) > div:nth-child(2) > svg:nth-child(1),
.screen .wp-top-bar > div:nth-child(2) > div:nth-child(2) > svg:nth-child(2),
.screen .wp-top-bar > div:nth-child(2) > div:nth-child(2) > svg:nth-child(3) {
  margin-left: 14px;
}
/* .screen .wp-top-bar > div:nth-child(2) > div:nth-child(2) > svg:nth-child(4) {
  margin-left: 70px;
} */

.msg {
  width: 100%;
  height: auto;
  display: block;
  overflow: hidden;
  top: 18px;
  position: relative;
}
.msg .bubble {
  float: left;
  max-width: 75%;
  width: auto;
  height: auto;
  display: block;
  background: #ebebeb;
  border-radius: 5px;
  position: relative;
  margin: 0px 0 3px 12px;
  box-shadow: 0 1px 0.5px rgba(0, 0, 0, 0.13);
}
.msg .bubble.alt {
  margin: 10px 15px 3px 0px;
  background: #dcf8c6;
  float: right;
}
.msg .bubble.follow {
  margin: 2px 0 3px 25px;
}
.msg .bubble.altfollow {
  margin: 2px 25px 3px 0px;
  background: #dcf8c6;
  float: right;
}
.msg .bubble .txt {
  padding: 5px 3px 2px 5px;
  width: 100%;
  display: table-cell;
}
.msg .bubble .txt .message {
  padding: 0 7px 5px 8px;
  margin: auto;
  display: table;
  font-size: 13px;
  line-height: 14px;
  letter-spacing: -0.24px;
  color: #000000;
  margin-top: 7px;
  text-align: left;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
}
.msg .bubble .txt .preview-footer {
  margin: 8px 0 0 0;
  font-size: 11px;
  color: lightslategrey;
}
.msg .bubble .txt .timestamp {
  font-size: 10px;
  margin: auto;
  padding: 0 6px 0 0;
  display: table;
  float: right;
  position: relative;
  text-transform: lowercase;
  color: rgba(0, 0, 0, 0.25);
}
.msg .bubble .bubble-arrow {
  position: absolute;
  float: left;
  left: -11px;
  top: 0px;
}
.msg .bubble .bubble-arrow.alt {
  bottom: 20px;
  left: auto;
  right: 4px;
  float: right;
}
.msg .bubble .bubble-arrow:after {
  content: "";
  position: absolute;
  border-top: 15px solid #ebebeb;
  border-left: 15px solid transparent;
  border-radius: 4px 0 0 0px;
  width: 0;
  height: 0;
}
.msg .bubble .bubble-arrow.alt:after {
  border-top: 15px solid #dcf8c6;
  transform: scaleX(-1);
}
.screen .wp-top-bar > div:nth-child(2) > div:first-child > span {
  border-radius: 100%;
  margin-left: 5px;
  margin-right: 8px;
  width: 0px;
  height: 20px;
}
.marvel-device.note8 .screen .wp-top-bar {
  position: sticky;
  top: 0;
  z-index: 1;
}
