.broadcast-overview-heading {
  width: 100%;
}
.broadcast-overview-heading > div > h2 {
  font-size: 20px;
  line-height: 32px;
  color: #000;
  margin-bottom: 0;
}
.broadcastOverviewAccordion {
  min-height: 0px !important;
  padding: 0px !important;
}
.broadcastOverviewAccordion > .MuiAccordionSummary-content {
  margin: 0px !important;
  padding: 0.3rem 0;
}

.broadcast-overview-heading > div > p {
  font-size: 14px;
  line-height: 32px;
  color: #6a696f;
}
.broadcast-overview-filters {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  padding: 18px 0px;
  /* margin-top: 45px; */
}
.broadcast-overview-filters > div:first-child {
  display: flex;
  width: 100%;
}
.broadcast-overview-filters .broadcast-filters-container {
  display: flex;
  align-items: center;
}
.broadcast-overview-filters .broadcast-filters-container {
  margin-right: 40px;
}
.broadcast-overview-filters .broadcast-filters-container > label {
  font-size: 16px;
  line-height: 13px;
  color: #000000;
  margin-right: 14px;
  margin-bottom: 0;
}
.broadcast-table-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  background: white;
  height: 45px;
  border-radius: 5px;
}
.broadcast-table-header > div:first-child {
  align-items: center;
}
.broadcast-table-header > div:first-child,
.broadcast-overview-table .list-group-item > div:nth-child(1) {
  display: flex;
  width: 15%;
  padding-left: 30px;
}
.broadcast-table-header > div > h4 {
  font-size: 15px;
  color: rgb(112, 112, 112);
  margin-bottom: 0;
}
.broadcast-table-header > div:nth-child(2) {
  display: flex;
  justify-content: space-between;
  width: 75%;
  align-items: center;
}
.broadcast-table-header > div:nth-child(2) > h4,
.broadcast-overview-table .list-group-item > div:nth-child(2) > h4 {
  width: 10%;
  font-size: 15px;
  color: rgb(112, 112, 112);
  margin-bottom: 0;
  text-align: center;
}
.broadcast-overview-table .list-group-item {
  border: none;
  background-color: white;
  border-radius: 5px;
  padding: 0.8rem 0;
}
.broadcast-overview-table .list-group-item > div:nth-child(2) {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
  width: 75%;
  align-items: baseline;
}
.broadcast-overview-table .list-group-item > div:nth-child(1) {
  width: 15%;
}
.broadcast-overview-table .list-group-item > div:nth-child(1) > span {
  margin-right: 10px;
  width: 40px;
}
.broadcast-overview-table
  .list-group-item
  > div:nth-child(1)
  > div:nth-child(2)
  > h6 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 14vw;
}
.tabs {
  padding: 10px 16px; 
  cursor: pointer;
}

.tab-selected {
  border-bottom: 3px solid #4c02ff;
  color: #4c02ff;
  font-weight: 600;
}
/* .broadcast-overview-table .list-group-item > div:nth-child(2) > p {
  width: 10%;
} */
.broadcast-channels-container {
  margin-top: 7.5rem;
}
.broadcast-channels-container > h4 {
  font-size: 18px;
  line-height: 32px;
  color: #4c02ff;
  text-align: left;
  /* margin-left: -5px; */
  margin-bottom: 21px;
}
.channel-radio-button {
  margin-top: 12px;
  position: absolute;
  right: 6%;
  top: 10%;
  z-index: 1;
  height: 24px;
  width: 24px;
}

input[type="radio"]:checked + .channel-radio-label {
  border: 2px solid #4c02ff;
}

input[type="radio"]:checked + .channel-radio-label .radio-icon:before {
  display: block;
  font-family: "FontAwesome";
  font-size: 23px;
  color: #2c6dec;
  content: "\f058";
  -webkit-transition: color 0.1s ease;
  transition: color 0.1s ease;
}

.broadcast-radio-huge {
  border-radius: 5px;
  height: 90px;
  margin: 20px 10px;
  padding: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  cursor: pointer;
  background: #fafafa;
}
.channel-radio-label {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 5px;
  margin-bottom: 0;
  height: 100%;
  padding: 17px 30px;
  width: 100%;
  cursor: pointer;
}
.channel-radio-label h4 {
  font-size: 16px;
  line-height: 22px;
  color: #343a4a;
  margin-top: 16px;
  text-align: center;
}
.channel-radio-label p {
  line-height: 16px;
  font-size: 14px;
}
.channel-radio-label span {
  color: #7c818d;
  font-size: 13px;
  text-align: center;
}

.channel-radio-label.makeitsmall {
  min-height: 75px !important;
}

/*.channel-radio-label svg {
    display: block;
    vertical-align: middle;
    max-width: 100%;
    max-height: 75px;
    height: auto;
    !*margin: 0 auto;*!
    width: auto;
    fill: #161616;
}*/
.channel-radio-label svg,
.channel-radio-label i {
  display: block;
  vertical-align: middle;
  max-width: 100%;
  max-height: 75px;
  height: auto;
  margin-right: 6px;
  width: 20px;
  font-size: 22px;
  color: #4c02ff;
  fill: #4c02ff;
}

.channel-radio-label .radio-icon {
  position: absolute;
  top: 12%;
  right: 0;
  padding: 0 1.25rem;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: all 0.1s ease-in;
  transition: all 0.1s ease-in;
}

.channel-radio-label .radio-icon:before {
  font-family: "FontAwesome";
  font-size: 23px;
  color: #f3f5f8;
  content: "\f058";
  -webkit-transition: color 0.1s ease;
  transition: color 0.1s ease;
}
.message-left-container {
  width: 50%;
}
/* .message-right-container {
  margin-top: 50px;
} */
.searchBroadcast {
  width: 30%;
  border: 1px solid grey;
  margin-left: 49%;
  margin-top: 0.1%;
  height: 80%;
  border-radius: 500px;
}
.createBroadcast {
  background-color: #4c02ff;
}
.wigzo-reach-container {
  border: 1px solid #a0a0a0;
  border-radius: 10px;
  text-align: left;
  padding: 16px 23px;
  width: 70%;
  margin: 0 auto;
}
.wigzo-reach-container > h4 {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #61656d;
}
.wigzo-reach-container > h3 {
  font-size: 24px;
  line-height: 36px;
  color: #565656;
}
.settings-radio-heading {
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #4c02ff;
  margin-top: 2.5rem;
}
.whatsappTemp-header {
  display: flex;
  justify-content: space-between;
}
.whatsappTemp-header > div:first-child {
  display: flex;
  align-items: center;
}
.whatsappTemp-header > div:first-child > a {
  font-size: 24px;
  margin-right: 21px;
}
.whatsappTemp-header > div:first-child > h3 {
  font-size: 20px;
  line-height: 32px;
  color: #000;
  margin-bottom: 0;
}
.waForm-subheading {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 5px;
  margin-top: 1.5rem;
}
.waForm-subheading > span {
  font-size: 14px;
  color: #7e7e7e;
  font-weight: 400;
}
.template-message-length {
  position: relative;
  float: right;
  bottom: 28px;
  right: 8px;
  font-size: 14px;
}
.sm-remove-hyperlink {
  position: relative;
  color: #4c02ff;
  font-size: 15px;
  cursor: pointer;
}
.sm-addVar-Btn {
  color: #4c02ff;
  position: absolute;
  right: 30px;
  cursor: pointer;
}
.button-options-div {
  padding-bottom: 20px;
  padding-top: 20px;
  border-bottom: 1px solid #c4c4c4;
}
.WA-create-headind {
  font-size: 18px;
  line-height: 27px;
  color: #4c02ff;
  margin-top: 43px;
  margin-bottom: 33px;
}
.whatsapp-overviewList-message {
  font-size: 13px;
  font-weight: 600;
  width: 40vw;
}
.audience-radio-container {
  text-align: left;
  display: flex;
  align-items: center;
  /* margin-bottom: 15px; */
}
.audience-radio-container > input {
  min-width: 20px;
  min-height: 20px;
  cursor: pointer;
}
.audience-radio-container > label {
  font-weight: 400;
  font-size: 18px;
  /* line-height: 32px; */
  color: #000000;
  margin-left: 30px;
  cursor: pointer;
}
.broadcast-message-container {
  display: flex;
  flex-direction: column;
}
.broadcast-message-container > div {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.message-alteration-options {
  display: flex;
  justify-content: flex-end !important;
  position: absolute;
  bottom: 0;
  right: 1.875rem;
}
.message-alteration-options > span {
  display: flex;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #4c02ff;
  margin-left: 30px;
  cursor: pointer;
  align-items: center;
}
.message-alteration-options > span > i {
  position: absolute;
  right: 0;
}

.recipe-cards-active {
  border: 2px solid #4c02ff;
  filter: drop-shadow(2px 2px 4px grey);
}
/* .recipe-cards:focus{
  border:2px solid black
} */

.disabledComingSoon {
  height: 0%;
  position: absolute;
  width: inherit;
  background-color: #c7c7c7;
  color: white;
  font-size: 20px;
  z-index: 1 !important;
  left: 0px;
  top: 0px;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
}

.recipe-cards:hover > .disabledComingSoon {
  display: flex;
  height: 100%;
}
.radio-button__huge:hover > .disabledComingSoon {
  display: flex;
  height: 100%;
}
.whatsapp-form-elements-div {
  display: flex !important;
  flex-wrap: wrap !important;
  justify-content: space-between !important;
}
/* 
.step-form-horizontal>div>div>span>span>svg{
  fill: #fff  ;
  border-color: #4c02ff;
}
.step-form-horizontal>div>div>span>span>svg>circle{
  stroke: #d7dae3;
  border-color:#d7dae3;
}
.step-form-horizontal>div>div>span>span>svg>text{
  fill: #000;
} */

.modal-content .modal-header .previewModalTitle {
  margin-left: 40%;
  border-top-right-radius: 2.25rem;
  border-top-left-radius: 2.25rem;
}
#Message .card-title {
  font-size: 15px;
}
#Message .card-header {
  padding: 1rem 1.875rem 1rem;
}
.broadcast-table-content {
  max-height: 55vh;
  overflow: scroll;
  min-height: 40vh;
}
.broadcast-view-details > p {
  width: 6vw;
  font-size: 13px;
  color: rgb(76, 2, 255);
  cursor: pointer;
  margin-bottom: 0px;
  white-space: nowrap;
}
.broadcast-view-details > span {
  display: flex;
  color: rgb(0, 0, 0);
  font-weight: 600;
  font-size: 14px;
  white-space: nowrap;
}
.broadcast-view-details > span > span {
  white-space: nowrap;
  /* overflow: hidden; */
  display: inline-block;
  text-overflow: ellipsis;
  width: 9vw;
  font-weight: 400;
  margin-left: 10px;
}

.meta-info {
  margin-top: 6px;
  color : #88898a;
  font-size: 15px;
}

.broadcast-checkbox-checkmark:checked {
  background-color: #2060C5;
  border: none;
}

.broadcast-checkbox-checkmark {
  position: absolute;
  top: 3px;
  left: 0;
  height: 22px;
  width: 22px;
  background-color: white;
  border: 1px solid #C0C0C0;
}

.custom-checkbox {
  cursor: pointer;
}

.custom-checkbox input:checked ~ .broadcast-checkbox-checkmark{
  background-color: #2060C5;
  border: none;
}

.custom-checkbox .label {
  margin-left: 0.3rem;
  color: #000000;
  font-size: 18px;
}

.broadcast-checkbox-checkmark:after {
  display: block;
  position: absolute;
  left: 7px;
  top: 2px;
  width: 7px;
  height: 13px;
  border: solid white;
  border-width: 0 3px 3px 0;
  content: "";
  /* -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg); */
  transform: rotate(45deg);
}


.pos-rel {
  position: relative;
}



.custom-checkbox-input {
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
}

/* margin classes  */
.mt-6px {
  
}

.ml-8 {
  margin-left:4rem;
}

.color-88898a {
}

.broadcast-actions {
  display: flex;
  position: absolute;
  top: 9rem;
  right:4rem;
}

.remove-file {
  text-decoration: none;
  color: #bf4343;
  cursor: pointer;
}

.br-10px {
  border-radius: 10px;
}

.bg-eee {
  background: #eee;
}

.b-ddd {
  border: 1px solid #ddd;
}

.color-bf4343 {
  color:#bf4343;
}

.disabled-button {
  cursor: pointer;
  border: 1px solid #c4c4c4;
  background: #C4C4C4;
  border-width: 0px;
  color: #fff;
  border-radius: 5px;
  font-size: 0.875rem;
  font-weight: 600;
  padding: 0.625rem 1rem;
  float: right;
}
.validate-button {
  cursor: pointer;
  border:1px solid #4C02FF;
  background: #4C02FF;
  border-width: 0px;
  color: #fff;
  border-radius: 5px;
  font-size: 0.875rem;
  font-weight: 600;
  padding: 0.625rem 1rem;
  float: right;
}