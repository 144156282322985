.parentBtn {
  background-color: transparent;
  display: flex;
  width: 100%;
}

.tabBtn {
  background-color: #f9f9f9;
  border: none;
  height: 150px;
  display: flex;
  align-items: center;
  padding: 1rem;
  width: 32.8%;
  box-shadow: rgba(0, 0, 0, 0.1) 1px 0px 0px 0px,
    rgba(0, 0, 0, 0.06) 1px 0px 0px 0px;
  margin-right: 1.5%;
}
.graph {
  box-shadow: rgba(76, 2, 255, 0.07) 0px 1px 1px 1px;
}

.right {
  flex: 1;
  justify-content: center;
  color: black;
}
h1 {
  color: black;
}
.tabBtn:focus {
  background-color: white;
}
.revenue-order-chart-container {
  background: #ffffff;
  border-radius: 10px;
  padding: 17px;
  margin-top: 27px;
}
.revenue-order-chart-container .chart-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
  padding-left: 19px;
}
.revenue-order-chart-container .chart-body {
  display: flex;
  align-items: center;
}
.graph-metrics .custom-legends {
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.graph-metrics .custom-legends li {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 8px;
}
.graph-metrics .custom-legends li span:first-child {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  margin-right: 10px;
}
.graph-metrics .custom-legends li span:nth-child(2) {
  color: #333333;
  font-size: 16px;
  font-weight: 500;
  transition: all 0.2s ease-in-out;
}
.hidden-series {
  filter: grayscale(1);
  opacity: 0.5;
  transition: all 0.2s ease-in-out;
}
.graph-metrics .custom-legends > h3 {
  line-height: 36px;
}
