.organisation-select-img {
  height: 53px;
}
.header-hyperlink {
  padding: 5px 17px;
  background: #e6e6e6;
  border-radius: 5px;
  margin-left: 8px;
  color: #000 !important;
}
.header-hyperlink > span > svg {
  margin-right: 12px;
}
.header-right > .header-hyperlink:nth-child(2) {
  margin-right: 30px;
}
