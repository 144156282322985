.broadcast-overview-heading {
  width: 100%;
}
.broadcast-overview-filters {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  padding: 18px 0px;
  /* margin-top: 45px; */
}
.broadcast-overview-filters > div:first-child {
  display: flex;
}
.broadcast-overview-filters .broadcast-filters-container {
  display: flex;
  align-items: center;
}
.broadcast-overview-filters .broadcast-filters-container {
  margin-right: 60px;
}
.broadcast-overview-filters .broadcast-filters-container > label {
  font-size: 16px;
  line-height: 13px;
  color: #000000;
  margin-right: 14px;
  margin-bottom: 0;
}
.broadcast-channels-container {
  margin-top: 3.5rem;
}
.broadcast-channels-container > h4 {
  font-size: 18px;
  line-height: 32px;
  color: #4c02ff;
  text-align: left;
  /* margin-left: -5px; */
  margin-bottom: 21px;
}
.channel-radio-button {
  margin-top: 12px;
  position: absolute;
  right: 6%;
  top: 10%;
  z-index: 1;
  height: 24px;
  width: 24px;
}

input[type="radio"]:checked + .channel-radio-label {
  border: 2px solid #4c02ff;
}
.recipe-radio-container > input[type="radio"] {
  visibility: hidden;
}
input[type="radio"]:checked + .channel-radio-label .radio-icon:before {
  display: block;
  font-family: "FontAwesome";
  font-size: 23px;
  color: #2c6dec;
  content: "\f058";
  -webkit-transition: color 0.1s ease;
  transition: color 0.1s ease;
}

.broadcast-radio-huge {
  border-radius: 5px;
  height: 90px;
  margin: 20px 10px;
  padding: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  cursor: pointer;
  background: #fafafa;
}
.channel-radio-label {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 5px;
  margin-bottom: 0;
  height: 100%;
  padding: 17px 30px;
  width: 100%;
  cursor: pointer;
}
.channel-radio-label h4 {
  font-size: 16px;
  line-height: 22px;
  color: #343a4a;
  margin-top: 16px;
  text-align: center;
}
.channel-radio-label p {
  line-height: 16px;
  font-size: 14px;
}
.channel-radio-label span {
  color: #7c818d;
  font-size: 13px;
  text-align: center;
}

.channel-radio-label.makeitsmall {
  min-height: 75px !important;
}

/*.channel-radio-label svg {
    display: block;
    vertical-align: middle;
    max-width: 100%;
    max-height: 75px;
    height: auto;
    !*margin: 0 auto;*!
    width: auto;
    fill: #161616;
}*/
.channel-radio-label svg,
.channel-radio-label i {
  display: block;
  vertical-align: middle;
  max-width: 100%;
  max-height: 75px;
  height: auto;
  margin-right: 6px;
  width: 20px;
  font-size: 22px;
  color: #4c02ff;
  fill: #4c02ff;
}

.channel-radio-label .radio-icon {
  position: absolute;
  top: 12%;
  right: 0;
  padding: 0 1.25rem;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: all 0.1s ease-in;
  transition: all 0.1s ease-in;
}

.channel-radio-label .radio-icon:before {
  font-family: "FontAwesome";
  font-size: 23px;
  color: #f3f5f8;
  content: "\f058";
  -webkit-transition: color 0.1s ease;
  transition: color 0.1s ease;
}
.searchBroadcast {
  width: 30%;
  border: 1px solid grey;
  margin-left: 49%;
  margin-top: 0.1%;
  height: 80%;
  border-radius: 500px;
}
.createBroadcast {
  background-color: #4c02ff;
}
.wigzo-reach-container {
  border: 1px solid #a0a0a0;
  border-radius: 10px;
  text-align: left;
  padding: 16px 23px;
  width: 70%;
  margin: 0 auto;
}
.wigzo-reach-container > h4 {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #61656d;
  display: flex;
}
.wigzo-reach-container > h4 > img {
  margin-left: 8px;
}
.wigzo-reach-container > h3 {
  font-size: 24px;
  line-height: 36px;
  color: #565656;
}
.settings-radio-heading {
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #4c02ff;
  margin-top: 2.5rem;
}
.sm-remove-hyperlink {
  position: relative;
  color: #4c02ff;
  font-size: 15px;
  cursor: pointer;
}
.sm-addVar-Btn {
  color: #4c02ff;
  position: absolute;
  right: 30px;
  cursor: pointer;
}
.button-options-div {
  padding-bottom: 20px;
  padding-top: 20px;
  border-bottom: 1px solid #c4c4c4;
}
.WA-create-headind {
  font-size: 18px;
  line-height: 27px;
  color: #4c02ff;
  margin-top: 43px;
  margin-bottom: 33px;
}
.whatsapp-overviewList-message {
  font-size: 13px;
  font-weight: 600;
  width: 40vw;
}
.audience-radio-container {
  text-align: left;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.audience-radio-container > input {
  min-width: 20px;
  min-height: 20px;
  cursor: pointer;
}
.audience-radio-container > label {
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  color: #000000;
  margin-left: 30px;
  cursor: pointer;
  display: flex;
}
.audience-radio-container > label > img {
  margin-left: 8px;
}
.message-alteration-options {
  display: flex;
  justify-content: flex-end !important;
  position: absolute;
  bottom: 0;
  right: 1.875rem;
}
.message-alteration-options > span {
  display: flex;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #4c02ff;
  margin-left: 30px;
  cursor: pointer;
  align-items: center;
}
.message-alteration-options > span > i {
  position: absolute;
  right: 0;
}

.recipe-cards-active {
  border: 2px solid #4c02ff;
  filter: drop-shadow(2px 2px 4px grey);
}
/* .recipe-cards:focus{
  border:2px solid black
} */

.disabledComingSoon {
  display: none;
  height: 100%;
  position: absolute;
  width: inherit;
  background-color: #c7c7c7;
  color: white;
  font-size: 20px;
  z-index: 1 !important;
  left: 0px;
  top: 0px;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
}

.recipe-cards:hover > .disabledComingSoon {
  display: flex;
}
.radio-button__huge:hover > .disabledComingSoon {
  display: flex;
}
.whatsapp-form-elements-div {
  display: flex !important;
  flex-wrap: wrap !important;
  justify-content: space-between !important;
}
.m-r-14 {
  margin-right: 7rem;
}
.newPostModal{
  display: flex;
  align-items: center;
}
.modal-title{
  margin: auto;
}
.modal-content{
  border-radius: 0px !important;
  border: none !important;
}
.modal-header{
  padding: 9px 0px 8px 0px !important;
  border-bottom: none !important;
}
.new-input{
  width: 100%;
  height: 43px;
  border: 1px solid #d7d7d7;
  padding: 0px 5px;
}
.modal-body{
  padding: 1.5rem !important;
}
.action-btn{
  width: 70px;
  height: 30px;
  margin: 10px;
  border-radius: 0px;
  font-size: 14px;
  color: #ffffff;
  background: #0f62fe!important;
}