[data-header-position="fixed"] .content-body {
  padding-top: 12rem;
}
.journey-recipe-container {
  margin-top: 7.5rem;
}
.category-radio-button {
  position: absolute;
  right: 6%;
  top: 10%;
  z-index: 1;
  height: 24px;
  width: 24px;
}
.radio-button__huge {
  background: #ffffff;
  border-radius: 20px;
  height: 244px;
  margin: 20px 10px;
  padding: 0 !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  cursor: pointer;
  background: #fff;
}

.radio-button__huge:hover {
  outline: 1px solid #4c02ff;
}
input[type="radio"]:checked + .category-radio-label {
  border: 2px solid #4c02ff;
}

input[type="radio"]:checked + .category-radio-label .radio-icon:before {
  display: block;
  font-family: "FontAwesome";
  font-size: 23px;
  color: #2c6dec;
  content: "\f058";
  -webkit-transition: color 0.1s ease;
  transition: color 0.1s ease;
}
.category-radio-label {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
  margin-bottom: 0;
  height: 100%;
  width: 100%;
  padding: 25px 30px;
}
.category-radio-label h4 {
  font-size: 16px;
  line-height: 22px;
  color: #343a4a;
  margin-top: 16px;
  margin-bottom: 14px;
  text-align: center;
}
.category-radio-label span {
  color: #7c818d;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
}

.category-radio-label.makeitsmall {
  min-height: 75px !important;
}

/*.category-radio-label svg {
    display: block;
    vertical-align: middle;
    max-width: 100%;
    max-height: 75px;
    height: auto;
    !*margin: 0 auto;*!
    width: auto;
    fill: #161616;
}*/
.category-radio-label svg,
.category-radio-label i {
  display: block;
  vertical-align: middle;
  max-width: 100%;
  max-height: 75px;
  height: auto;
  margin-right: 6px;
  width: 20px;
  font-size: 22px;
  color: #4c02ff;
  fill: #4c02ff;
}

.category-radio-label .radio-icon {
  position: absolute;
  top: 12%;
  right: 0;
  padding: 0 1.25rem;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: all 0.1s ease-in;
  transition: all 0.1s ease-in;
}

.category-radio-label .radio-icon:before {
  font-family: "FontAwesome";
  font-size: 23px;
  color: #f3f5f8;
  content: "\f058";
  -webkit-transition: color 0.1s ease;
  transition: color 0.1s ease;
}

.recipe-template-parent-container > div {
  width: 100%;
  margin-bottom: 2%;
}
.recipe-template-parent-container .recipe-cards {
  background: #ffffff;
  border-radius: 20px;
  height: 320px;
  padding: 20px 35px 24px 34px;
  margin: 20px 22px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.recipe-template-parent-container .recipe-cards * {
  text-align: left;
}
.recipe-template-parent-container .recipe-cards > i {
  font-size: 20px;
  position: absolute;
  right: 0.8rem;
  cursor: pointer;
}

.recipe-template-parent-container .recipe-cards > h4 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 18px;
  color: #000;
  min-height: 50px;
  width: 50%;
}
.recipe-template-parent-container .recipe-cards > img {
  position: absolute;
  right: 32px;
  top: 22px;
}
.recipe-template-parent-container .recipe-cards > p {
  font-size: 16px;
  margin-bottom: 24px;
  margin-top: 42px;
  min-height: 80px;
  padding-right: 30px;
  color: #7e7e7e;
  text-align: left;
  line-height: 24px;
}
.recipe-template-parent-container .recipe-cards > button {
  background: #ffffff;
  border: 1px solid #4c02ff;
  border-radius: 100px;
  padding: 5px 25px;
  color: #4c02ff;
}
.recipe-template-parent-container .recipe-cards > div > span {
  font-weight: 600;
  font-size: 12px;
  line-height: 30px;
  color: #7e7e7e;
  text-transform: capitalize;
}
.recipe-radio-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.recipe-radio-button {
  display: none;
}
.recipe-radio-label {
  background: #ffffff;
  border: 1px solid #4c02ff;
  border-radius: 5px;
  padding: 0.625rem 1rem;
  color: #4c02ff;
  cursor: pointer;
  font-size: 0.875rem;
}
input[type="radio"]:checked + .recipe-radio-label {
  background: #4c02ff;
  color: #fff;
}
.journey-nav-btns {
  position: fixed;
  right: 15px;
  top: 4rem;
  background: #f2f2f2;
  width: 142%;
  height: 95px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.whatsapp-preview-container {
  /* height: 100vh; */
  display: flex;
  align-items: center;
}
.marvel-device.note8 {
  width: 215px;
  height: 445px;
  margin: 0 auto;
}

.disabledIcon {
  filter: grayscale(1);
}
.disabledComingSoon {
  display: none;
}
.recipe-cards:hover {
  border: 1px solid #4c02ff;
}
/* .recipe-cards:active,.recipe-cards:focus{
  border:2px solid #4c02ff;
  filter: drop-shadow(2px 2px 4px grey);
}  */

.recipe-cards + .disabledComingSoon:hover {
  height: 100%;
  position: absolute;
  width: inherit;
  background-color: grey;
  color: black;
  left: 0px;
  top: 0px;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
}
.audience-left-container {
  min-width: 75%;
}
.wigzo-hyperlink {
  color: #7a54ed !important;
  cursor: pointer !important;
  margin-top: 5px;
  font-size: 14px;
}
.wigzo-hyperlink > img {
  margin-right: 3px;
}
.custom-card-container {
  background: #ffffff;
  border-radius: 10px;
  padding: 28px;
  width: 100%;
}
.custom-card-heading {
  font-weight: 600;
  font-size: 18px;
  color: #181818;
}
.custom-card {
  background: #ffffff;
  border-radius: 10px;
  width: 244px;
  height: 197px;
  margin: 20px 17px 20px 0;
  padding: 0 !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  border: 1px solid #d8d8d8;
}
.custom-card:last-child {
  margin-right: 0;
}
.custom-card > img,
.custom-card > svg {
  width: 30px;
  margin-bottom: 23px;
}
.custom-card > h4 {
  font-size: 16px;
  color: #686868;
}
.custom-card > button {
  margin-top: 22px;
}
.disabled-custom-card {
  background: #f5f5f5;
}
.disabled-custom-card > img {
  filter: grayscale(1);
}
.disabled-custom-card > button {
  color: #b1b1b1;
  border-color: #b1b1b1;
  pointer-events: none;
}
.gallery-template-container {
  border-top: 1px solid #cfcfcf;
  display: flex;
  width: 100%;
  max-height: 55vh;
}
.gallery-template-cards-container {
  width: 75%;
  display: flex;
  flex-wrap: wrap;
  padding: 24px;
  padding-bottom: 0;
  gap: 21px 15px;
  /* max-height: 55vh; */
  overflow: scroll;
}
.gallery-template-card {
  background: #fff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  border: 1px solid #d8d8d8;
  padding: 14px;
  width: 23.6%;
  height: 260px;
  gap: 10px;
}
.gallery-template-details {
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  height: 70%;
  overflow: hidden;
  background: #e1ffc9;
  border-radius: 10px;
  transition: height 0.5s ease 0s;
  cursor: pointer;
}
.gallery-template-details .img-div {
  display: flex;
  width: 100%;
  justify-content: center;
  border-radius: 10px;
}
.gallery-template-details .message {
  padding: 0 7px 5px 8px;
  display: table;
  font-size: 13px;
  line-height: 14px;
  color: #000;
  text-align: left;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
}
.gallery-template-card p {
  width: 98%;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  margin-bottom: 5px;
}
.gallery-template-card .gallery-template-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.gallery-template-card .gallery-template-actions span {
  font-size: 12px;
  font-weight: 500;
  padding: 2px 10px;
  line-height: 19px;
  text-transform: lowercase;
}
.gallery-template-card .gallery-template-actions span:first-letter {
  text-transform: capitalize;
}
.gallery-template-card .gallery-template-actions a {
  font-size: 14px;
  white-space: nowrap;
}
.gallery-template-preview-container {
  width: 25%;
  border-left: 1px solid #cfcfcf;
  padding: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.gallery-template-preview-container > div {
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}
.gallery-template-preview-container > div.template-preview-empty-state {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}
.gallery-template-preview-container > div h4 {
  text-align: center;
  font-weight: 600;
}
.gallery-template-preview-container .marvel-device.note8 {
  /* background: unset; */
  display: block;
  /* width: 80%; */
  width: 250px;
  height: 515px;
}
.gallery-template-preview-banner {
  /* width: 112%;  */
  margin: 12px 0 17px 0;
  /* padding: 18px 20px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.gallery-template-preview-banner span {
  text-transform: lowercase;
  background: unset;
}
.gallery-template-preview-banner p {
  margin: 0;
  font-size: 0.875rem;
  line-height: 2;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.gallery-template-preview-banner p b {
  color: #414044;
  font-weight: 500;
}
.gallery-template-preview-banner span::first-letter {
  text-transform: capitalize;
}
.badge-purple-lt {
  background: #4c02ff17;
  border-radius: 3px;
  color: #4c02ff;
}
.badge-green-lt {
  background: #edf7e9;
  border-radius: 3px;
  color: #4caf50;
}
.badge-red-lt {
  background: #ffe9e9;
  border-radius: 3px;
  color: #fb6262;
}
.badge-yellow-lt {
  background: #fffcdd;
  border-radius: 3px;
  color: #ba9f0f;
}
