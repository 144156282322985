@font-face {
  font-family: sf pro display;
  font-style: normal;
  font-weight: 400;
  src: local("SF Pro Display"),
    url(https://fonts.cdnfonts.com/s/59278/SFPRODISPLAYREGULAR.woff)
      format("woff");
}
@font-face {
  font-family: sf pro display;
  font-style: italic;
  font-weight: 100;
  src: local("SF Pro Display"),
    url(https://fonts.cdnfonts.com/s/59278/SFPRODISPLAYULTRALIGHTITALIC.woff)
      format("woff");
}
@font-face {
  font-family: sf pro display;
  font-style: italic;
  font-weight: 200;
  src: local("SF Pro Display"),
    url(https://fonts.cdnfonts.com/s/59278/SFPRODISPLAYTHINITALIC.woff)
      format("woff");
}
@font-face {
  font-family: sf pro display;
  font-style: italic;
  font-weight: 300;
  src: local("SF Pro Display"),
    url(https://fonts.cdnfonts.com/s/59278/SFPRODISPLAYLIGHTITALIC.woff)
      format("woff");
}
@font-face {
  font-family: sf pro display;
  font-style: normal;
  font-weight: 500;
  src: local("SF Pro Display"),
    url(https://fonts.cdnfonts.com/s/59278/SFPRODISPLAYMEDIUM.woff)
      format("woff");
}
@font-face {
  font-family: sf pro display;
  font-style: italic;
  font-weight: 600;
  src: local("SF Pro Display"),
    url(https://fonts.cdnfonts.com/s/59278/SFPRODISPLAYSEMIBOLDITALIC.woff)
      format("woff");
}
@font-face {
  font-family: sf pro display;
  font-style: normal;
  font-weight: 700;
  src: local("SF Pro Display"),
    url(https://fonts.cdnfonts.com/s/59278/SFPRODISPLAYBOLD.woff) format("woff");
}
@font-face {
  font-family: sf pro display;
  font-style: italic;
  font-weight: 800;
  src: local("SF Pro Display"),
    url(https://fonts.cdnfonts.com/s/59278/SFPRODISPLAYHEAVYITALIC.woff)
      format("woff");
}
@font-face {
  font-family: sf pro display;
  font-style: italic;
  font-weight: 900;
  src: local("SF Pro Display"),
    url(https://fonts.cdnfonts.com/s/59278/SFPRODISPLAYBLACKITALIC.woff)
      format("woff");
}

.authLogin {
  display: flex;
  flex-direction: column;
  background: white;
  height: fit-content;
  position: absolute;
  left: 26%;
  padding: 2rem;
  width: 52%;
  top: 15%;
  border: 1px solid #4c02ff;
}
.mainContainer {
  height: 100vh;
  width: 100vw;
  background-image: url(../../src/images/wigzolite\ icons/login/newbackgroundloginsignup.svg);
  background-repeat: no-repeat;
  background-size: cover;
}
.form-input {
  background-color: white !important;
  border: 1px solid rgb(219, 219, 219);
}
.topRightborder {
  position: absolute;
  right: 0px;
}
.wigzoLogo {
  align-self: center;
  justify-content: flex-start;
  width: auto;
}
.cardbodyCustom {
  width: 80%;
}
.wigzoBtn {
  color: white !important;
  background-color: rgb(76, 2, 255);
}
.wigzoTerms {
  color: rgb(76, 2, 255);
}
.orgImg {
  height: 50px;
  width: 50px;
}
.orgName {
  font-size: 16px;
  font-weight: 500;
  width: 125px;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-left: 10px;
}
.domain {
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-left: 10px;
}
.orgContent {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  justify-content: space-around;
  position: fixed;
  top: 22%;
  border: 1px solid #4c02ff;
  left: 11%;
  width: 500px;
  background-color: white !important;
}
.orgBtn {
  background-color: #4c02ff;
  height: 50px;
  width: 200px;
  padding: 3px;
  margin-left: 10px;
}
.orgScroll {
  overflow-y: scroll;
  max-height: 400px;
}
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-corner {
  background-color: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #4c02ff;
  border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #4c02ff;
}
.landing-page-container * {
  font-family: "SF Pro Display", sans-serif !important;
}
.landing-page-container .accordion__header--indicator {
  font-family: "themify" !important;
}
.landing-page-container {
  max-width: 1400px;
  margin: 20px auto;
  padding: 25px;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgb(245 10 236 / 4%) 51%,
    rgb(71 227 240 / 6%) 100%
  );
  box-shadow: 0px 0px 2px #450af51d;
  border-radius: 12px;
}
.landing-page-container > div:first-child {
  display: flex;
  align-items: center;
  background: url(../../src/images/wigzolite\ icons/login/conversion-bg.png);
  background-size: cover;
  padding: 20px 50px;
  border-radius: 15px;
}
.landing-page-container > div:first-child > div:first-child > h1 {
  font-family: "SF Pro Display", sans-serif;
  font-size: 48px;
  font-weight: bolder;
  color: #0b0757 !important;
  margin: 0;
}
.landing-page-container > div:first-child > div:first-child > h2 {
  background: transparent
    linear-gradient(261deg, #47e3f0 0%, #8c4bd8 100%, #000000 100%) 0% 0%
    no-repeat padding-box;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  font-size: 48px;
  font-weight: 600;
  width: max-content;
}
.landing-page-container > div:first-child > div:first-child > p {
  color: #272727cc;
  font-family: "SF Pro Display", sans-serif;
  font-size: 15px;
  margin-bottom: 35px;
  max-width: 70%;
}
.landing-page-container > div:first-child > div:first-child > div {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.landing-page-container > div:nth-child(2) {
  margin: 30px 0;
  padding: 20px;
}
.landing-page-container > div:nth-child(2) > p {
  font-size: 20px;
  font-weight: 500;
  color: #000;
}
.landing-page-container > div:nth-child(2) > div:nth-child(2) {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}
.landing-page-container > div:nth-child(2) > div:nth-child(2) > div > h2 {
  font-size: 35px;
  font-weight: 600;
  color: #0a0753;
  display: flex;
  align-items: center;
}
.landing-page-container > div:nth-child(2) > div:nth-child(2) > div > h2 > img {
  margin-right: 10px;
}
.landing-page-container > div:nth-child(2) > div:nth-child(2) > div > span {
  color: #8d8ab4;
  margin-left: 15px;
}
.landing-page-container > div:nth-child(3) {
  padding: 20px;
  margin-bottom: 30px;
}
.landing-page-container > div:nth-child(3) > h1 {
  font-size: 44px;
  font-weight: 600;
}
.landing-page-container > div:nth-child(3) > h1 > span {
  background: transparent
    linear-gradient(261deg, #47e3f0 0%, #8c4bd8 100%, #000000 100%) 0% 0%
    no-repeat padding-box;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  width: max-content;
}
.landing-page-container > div:nth-child(3) > div {
  display: flex;
  flex-wrap: wrap;
  margin-top: 35px;
}
.landing-page-container > div:nth-child(3) > div > div {
  flex: 1 1 30%;
  background: #faf8ff;
  border: 1px solid #0000000a;
  border-radius: 6px;
  padding: 20px;
  margin-right: 30px;
  margin-bottom: 30px;
}
.landing-page-container > div:nth-child(3) > div > div > h4 {
  font-size: 20px;
  font-weight: 600;
  color: #0b0757;
  margin-bottom: 0;
}
.landing-page-container > div:nth-child(3) > div > div > h4 > img {
  margin-right: 15px;
  width: 45px;
}
.landing-page-container > div:nth-child(3) > div > div > span {
  margin-left: 65px;
  color: #828282;
  display: block;
}

.landing-page-container > div:nth-child(4) > h1 {
  font-size: 44px;
  font-weight: 600;
}
.landing-page-container > div:nth-child(4) > h1 > span {
  background: transparent
    linear-gradient(261deg, #47e3f0 0%, #8c4bd8 100%, #000000 100%) 0% 0%
    no-repeat padding-box;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  width: max-content;
}
.landing-page-container > div:nth-child(4) > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fffded;
  padding: 45px 40px 45px 70px;
  border-radius: 15px;
  margin-top: 50px;
}
.landing-page-container > div:nth-child(4) > div > div {
  max-width: 50%;
}
.landing-page-container > div:nth-child(4) > div > div > h1 {
  font-size: 34px;
  color: #0b0757;
  font-weight: 600;
  margin-bottom: 5px;
}
.landing-page-container > div:nth-child(4) > div > div > div {
  background: #fff;
  padding: 12px 20px;
  margin-bottom: 20px;
  border: 1px solid #0000000f;
  border-radius: 0px 12px 12px 0px;
}
.landing-page-container > div:nth-child(4) > div > div > div > p {
  font-size: 20px;
  font-weight: 600;
  color: #6149cd;
  margin-bottom: 0;
}
.landing-page-container > div:nth-child(4) > div > div > div > span {
  font-size: 15px;
  color: #272727cc;
}
.start-now-sticker {
  background: #1d113e;
  border-radius: 22px;
}
.start-now-sticker > div:first-child {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 270px;
  margin-top: 70px;
}
.start-now-sticker > div:first-child > div:first-child {
  max-width: 40%;
}
.start-now-sticker > div:first-child > div > h1 {
  font-size: 44px;
  color: #fff;
  font-weight: 600;
}
.start-now-sticker > div:first-child > div > p {
  color: #e8e7ec;
}
.start-now-sticker > div:first-child > div:nth-child(2) > img {
  margin: 0 40px;
}
.start-now-sticker > div:first-child > div:nth-child(2) > button {
  border-radius: 10px;
  padding: 11px 40px;
  background: transparent linear-gradient(303deg, #56d2ff 0%, #92ffbc 100%) 0%
    0% no-repeat padding-box;
  color: #000;
  font-size: 20px;
  font-weight: 600;
}
.start-now-sticker > div:last-child {
  width: 100%;
  height: 25px;
  background: transparent linear-gradient(274deg, #56d2ff 0%, #92ffbc 100%) 0%
    0% no-repeat padding-box;
  border-radius: 0 0 20px 20px;
}
.FAQ-accordian {
  margin-top: 80px;
}
.FAQ-accordian > h1 {
  font-size: 44px;
  font-weight: 600;
  color: #0b0757;
  margin-bottom: 40px;
}
.FAQ-accordian > h1 > span {
  background: transparent
    linear-gradient(261deg, #47e3f0 0%, #8c4bd8 100%, #000000 100%) 0% 0%
    no-repeat padding-box;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  width: max-content;
}
.FAQ-accordian .accordion__item .accordion__header {
  font-weight: bold;
  background-color: #fff;
  margin-bottom: 10px;
}
.FAQ-accordian
  .accordion-no-gutter
  .accordion__item:first-child
  .accordion__header {
  box-shadow: 0px 6px 6px #00000008;
  border-radius: 12px 12px 0px 0px;
}
.FAQ-accordian .accordion__item {
  margin-bottom: 10px;
}
.FAQ-accordian .accordion__item .accordion__body--text {
  background: #fff;
}
