.MuiOutlinedInput-input {
  padding: 10px 35px 10px 14px !important;
}
.MuiInputBase-root {
  color: #373737;
}
.custom-selectable-secondary-dropdown .MuiOutlinedInput-input {
  max-width: 120px;
}
.custom-selectable-secondary-dropdown .MuiOutlinedInput-root {
  border: 1px solid #7d50e4;
  background: #fff;
}
textarea.MuiOutlinedInput-input {
  padding: unset !important;
}
.custom-selectable-dropdown .MuiOutlinedInput-root,
.custom-selectable-dropdown
  .MuiOutlinedInput-root:hover
  .MuiOutlinedInput-notchedOutline {
  width: 100%;
  /* border: 1px solid #0000003b; */
}
.custom-selectable-dropdown
  .MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline,
.custom-selectable-secondary-dropdown
  .MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border-color: #7d50e4;
  border-width: 1px;
}
.custom-selectable-dropdown .MuiSelect-select,
.custom-selectable-secondary-dropdown .MuiSelect-select {
  border-radius: 5px !important;
  background-color: #fff !important;
  color: #7e7e7e;
}
.MuiListItem-root.Mui-selected,
.MuiListItem-root.Mui-selected:hover {
  color: #40189d;
  background-color: #40189d1a !important;
}
.rs-picker-default .rs-picker-toggle {
  border: 1px solid #40189d;
}
.rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-caret,
.rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-clean,
.rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-caret,
.rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-clean {
  top: 0.58rem;
}
.error.rs-picker-default .rs-picker-toggle {
  border: 1px solid #f44336;
}
.rs-picker-default .rs-picker-toggle.rs-btn {
  border-radius: 5px;
  padding-bottom: 0.625rem;
  padding-top: 0.625rem;
}
.rs-picker-has-value .rs-btn .rs-picker-toggle-value,
.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
  color: #7e7e7e;
}
.rs-picker-default:not(.rs-picker-disabled) .rs-btn-active,
.rs-picker-default:not(.rs-picker-disabled) .rs-btn:focus,
.rs-picker-default:not(.rs-picker-disabled) .rs-btn:hover,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle-active,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:focus,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover {
  border-color: #4c02ff;
  outline: none;
  box-shadow: rgb(215 215 217) -2px 2px 4px;
}
.rs-picker-default:not(.rs-picker-disabled)
  .rs-btn-active
  .rs-picker-toggle-value,
.rs-picker-default:not(.rs-picker-disabled)
  .rs-btn:focus
  .rs-picker-toggle-value,
.rs-picker-default:not(.rs-picker-disabled)
  .rs-btn:hover
  .rs-picker-toggle-value,
.rs-picker-default:not(.rs-picker-disabled)
  .rs-picker-toggle-active
  .rs-picker-toggle-value,
.rs-picker-default:not(.rs-picker-disabled)
  .rs-picker-toggle:focus
  .rs-picker-toggle-value,
.rs-picker-default:not(.rs-picker-disabled)
  .rs-picker-toggle:hover
  .rs-picker-toggle-value {
  color: #4c02ff;
}
.disabled-state {
  color: grey !important;
  cursor: not-allowed !important;
}
.dashboard-widgets {
  display: flex;
  flex-wrap: wrap;
  gap: 1.2%;
}
.widget-container {
  background: #ffffff;
  border-radius: 10px;
  padding: 15px 22px;
  display: flex;
  justify-content: space-between;
  flex: 1;
}
.widget-data .widget-info > h5 {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}
.widget-data .widget-info > img {
  margin-bottom: 0.5rem;
  margin-left: 8px;
}
.widget-data .widget-count {
  font-weight: 500;
  font-size: 22px;
  line-height: 33px;
  color: #484848;
}
.search-icon {
  cursor: pointer;
  position: relative;
  left: 30px;
  z-index: 1;
}
.swal-primary-btn {
  background-color: #4c02ff !important;
}
.swal-outline-primary-btn {
  color: #4c02ff !important;
  border-color: #4c02ff !important;
  background-color: #fff !important;
}
.swal-outline-primary-btn:hover {
  color: #fff !important;
  background-color: #4c02ff !important;
  border-color: #4c02ff !important;
}
.swal-button--loading {
  color: transparent !important;
}
