.parentOrg {
  width: 17%;
  transition: left 0.2s;
  position: fixed;
  background-color: #efefef;
  left: 13%;
  border-right: 1px solid #bfbfbf;
  top: 0%;
  height: 100%;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2px;
}
.menu-toggle .parentOrg {
  left: 3.91rem !important;
}
.menu-toggle .overlay {
  left: calc(17% + 3.91rem);
}
.menu-toggle .trigger {
  border: 0;
}
.overlay {
  display: none;
  position: fixed;
  width: 100%;
  z-index: 999999999 !important;
  height: 100vh;
  left: 30.5%;
  top: 4rem;
  background-color: #00000038;
}
.orgList {
  position: fixed;
  top: 0vh;
  background: #f2f2f2;
  left: 15vw;
  width: 15%;
  height: 100vh;
}
.listGroup {
  margin-top: 40% !important;
}

.orgListContent {
  overflow-x: hidden;
  background: transparent;
  max-height: 750px;
  overflow-y: scroll;
}
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(212, 212, 212) !important ;
  border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(212, 212, 212);
}
/* clears the ‘X’ from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}
.searchOrg {
  display: block;
  height: 40px;
  border: 1px solid grey;
}
.list-item {
  border: none;
  min-width: 20%;
}
.orgInfo {
  cursor: pointer;
}
.orgLogo {
  max-height: 50px;
  width: 50px;
  border-radius: 500px;
}
.domainName {
  font-size: 14px;
}
.orgName {
  font-size: 14px;
}

@media only screen and (max-width: 1699px) {
  .domainName {
    font-size: 13px;
  }
}

@media only screen and (max-width: 1499px) {
  .domainName {
    font-size: 11px;
  }
}

@media only screen and (max-width: 1399px) {
  .domainName {
    font-size: 10px;
  }
}
