/* .modal .modal-dialog {
    min-width: 750px;
} */

.modal .modal-body {
    padding: 2rem;
    min-width: 400px;
}

.close-icon {
    position: absolute;
    right: 0;
}

.upload-csv {
    border: 3px dashed #dfe0e2;
    border-radius: 4px;
}

.color-61656d {
    color: #61656d;
}

.color-50c878 {
    color: #50c878;
}

.color-000000 {
    color : #000000;
}

.color-FF6961 {
    color: #FF6961;
}

.fw-600 {
    font-weight: 600;
}

.cursor {
    cursor: pointer;
}