.gallery-modal .modal-dialog {
  width: 100%;
  max-width: 1000px;
  min-width: 900px;
  margin: 60px auto 0px auto;
}
.gallery-modal .modal-content {
  border: none;
  box-shadow: -2px 2px 4px rgb(118 118 118 / 54%);
}
.gallery-modal .modal-body {
  display: flex;
  padding: 0;
  height: auto;
  border-top: none;
}
.gallery-modal .modal-header {
  padding: 15px 20px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: none;
}
.gallery-modal .modal-header .close {
  padding: 0.875rem 0;
}
.gallery-modal .modal-header .rulehead {
  margin: 0;
}
.gallery-modal .modal-footer {
  display: flex;
  padding: 15px;
  align-items: center;
  justify-content: flex-end;
}
li.switch-tabs {
  margin: 0;
  font-size: 15px;
  font-weight: 500;
  color: #161616;
}
li.switch-tabs > svg {
  margin-right: 10px;
}
#image_library_tab:after {
  content: "Image Library";
  margin-left: 10px;
}
#upload_tab:after {
  content: "Upload";
  margin-left: 10px;
}
#product_catalog_tab:after {
  content: "Product Catalog";
  margin-left: 10px;
}
.asset-details .asset-info {
  margin-left: 15px;
}
.asset-details .asset-info h4 {
  margin: 0;
  font-size: 15px;
  font-weight: 500;
  color: #161616;
}
.asset-details .asset-info p {
  font-size: 15px;
  color: #373f47;
  font-weight: 300;
  margin: 0;
}
.wigzo-gallery-images ul li .asset-details .asset-thumbnail {
  width: 50px;
  height: 50px;
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in;
  display: block;
}
.wigzo-gallery-images,
.wigzo-gallery-products {
  height: 100%;
  overflow: hidden;
}
/* .modal-title {
  margin: 15px 0 30px 0;
} */
.close.btn {
  margin: 5px 0;
  display: flex;
  justify-content: flex-end;
}
.close.btn > span {
  font-size: 24px;
  margin-right: 18px;
  color: #4c02ff;
  font-weight: 400;
}
.close.btn:focus {
  box-shadow: none;
}
.wigzo-gallery-nav {
  height: 100%;
  width: 18%;
  padding-left: 18px;
}
.wigzo-gallery-nav ul {
  list-style-type: none;
  /* padding: 10px 0; */
}
.wigzo-gallery-nav ul li {
  display: flex;
  min-height: 45px;
  height: 45px;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  padding-left: 10px;
  margin-bottom: 5px;
}
.wigzo-gallery-nav ul li h4 {
  margin: 0;
  margin-left: 10px;
  font-size: 15px;
  font-weight: 500;
}
.wigzo-gallery-nav ul li.activeTab {
  background: #f2f2f2;
  color: #4e4c53;
  border-radius: 2px;
}
.wigzo-gallery-content {
  width: 82%;
  display: flex;
  flex-direction: column;
  background: #f2f2f2;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.wigzo-gallery-images ul,
.wigzo-product-catalog ul {
  list-style-type: none;
  padding-left: 0;
  height: 100%;
  overflow: auto;
  overflow-x: hidden;
}
.wigzo-gallery-images ul li {
  padding: 13px 15px;
  display: flex;
  align-items: center;
  background: #fff;
  margin: 5px 20px;
  border-radius: 5px;
}
.wigzo-gallery-images ul li .asset-details {
  width: 100%;
  display: flex;
  align-items: center;
}
.wigzo-gallery-upload {
  padding: 50px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.wigzo-gallery-loader {
  height: 97%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.asset-action-button {
  display: flex;
  align-items: center;
}
.gallery-pagination {
  margin: 10px 0;
}
.gallery-modal .dzu-dropzone {
  min-height: 170px;
  max-height: 220px;
  width: 100%;
  background-color: #fff;
  border: 1px dashed #4c02ff;
  overflow: hidden;
  border-radius: 10px;
}
.gallery-modal .dzu-inputLabel {
  font-weight: 400;
  font-size: 16px;
  color: #824fff;
}
