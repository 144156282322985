body {
  margin: 0;
  padding: 0;
  font-family: "Poppins";
}
.channels-permission-list {
  background-color: #f2f2f2;
  display: flex;
  flex-direction: column;
  height: -moz-fit-content;
  height: fit-content;
  padding: 0rem 1rem;
  max-width: 1400px;
  margin: 10% auto;
}
.headings {
  margin-bottom: 1rem;
}
.channels-permission-list .heading {
  font-size: 20px;
  font-weight: 600;
  color: #4d4c50;
  line-height: 30px;
}
.channels-permission-list .sub-heading {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #61576e;
}
.table-headings {
  font-weight: 600;
  font-size: 18px;
  margin-top: 15px;
  padding: 1.8rem 2.2rem;
  display: flex;
  justify-content: space-between;
  background: #fff;
  border-radius: 5px;
  color: #363636;
  align-items: center;
}
.table-values {
  font-weight: 600;
  font-size: 18px;
  margin-top: 15px;
  padding: 0rem 2.2rem;
  display: flex;
  justify-content: space-between;
  background: #fff;
  border-radius: 5px;
  color: #363636;
  align-items: center;
}
.table-content {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  height: fit-content;
  font-size: 15px;
}
.table-headings > *,
.table-values > * {
  width: 20%;
}
.grant-permission {
  border: 1px solid #4c02ff;
  font-size: 15px;
  font-weight: 500;
  line-height: 22.5px;
  size: 15px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #4c02ff;
  border-radius: 20px;
  width: auto;
  padding: 10px 22px;
  white-space: nowrap;
}
/* .info{
    width: 10vw;
    position: relative;
    background: #fff;
    z-index: 2;
    top: 60%;
    left: 6%;
    padding: 0.3rem;
    flex-wrap: wrap;
}

.info:hover {
    visibility: visible;
display: flex;
} */

a {
  text-decoration: none;
}

.popover__title {
  font-size: 24px;
  line-height: 36px;
  text-decoration: none;
  color: #4d4c50;
  text-align: center;
  padding: 15px 0;
}

.popover__wrapper {
  position: relative;
  display: inline-block;
}
.popover__content {
  font-size: 12px;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  left: -150px;
  transform: translate(0, 10px);
  background-color: #fff;
  padding: 0.5rem;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  width: auto;
}
.popover__content:before {
  position: absolute;
  z-index: -1;
  content: "";
  right: calc(50% - 10px);
  top: -8px;
  border-style: solid;
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent #fff transparent;
  transition-duration: 0.3s;
  transition-property: transform;
}
.popover__wrapper:hover .popover__content {
  z-index: 10;
  opacity: 1;
  visibility: visible;
  transform: translate(0, -20px);
  transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
}
.popover__message {
  text-align: center;
}

.info-message {
  text-align: center;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #8483a3;
  text-align: center;
}
.success-message {
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: center;
}

.thank_you_page_container {
  height: 100%;
  background: #fff;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
}
.goToHomePage {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #4026f5;
  border-radius: 30px;
  border: 1px solid #4026f5;
  background: #ffffff;
  padding: 0.5rem 3rem;
}
.thank_you_container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 85vh;
  background-color: inherit;
  padding: 3rem;
}
.sub-container {
  height: fit-content;
  padding: 1rem;
  display: flex;
  height: 65%;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
}
.TC-modal {
  white-space: pre-wrap;
}
.TC-modal .modal-content {
  max-height: 80vh;
  border: none !important;
}
.TC-modal .modal-content::-webkit-scrollbar {
  display: block;
}
.TC-modal .modal-content a {
  color: #7a54ed !important;
  cursor: pointer;
}
.TC-modal .modal-dialog {
  max-width: 665px;
  margin: 3rem auto;
}
.TC-table > tr > td {
  vertical-align: baseline;
  border: 1px solid #000;
  padding: 15px;
}
.TC-table > tr > td:first-child {
  width: 20%;
}
.landing-page-container a {
  color: #7a54ed !important;
  cursor: pointer !important;
  text-decoration: underline !important;
}
.TC-modal .modal-content .modal-header {
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 1;
  border: 0;
}
.TC-modal .modal-content .modal-body {
  overflow-y: scroll;
  overflow-x: hidden;
}
